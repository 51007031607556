import React from "react"
import {Grid, Box, Typography,makeStyles} from "@material-ui/core";
// import { makeStyles } from '@mui/styles';

const useStyles=makeStyles((theme)=>({
    main:{
        maxWidth:600,
        margin:theme.spacing(2,"auto")
    }
}))


function LandingPage(){
    const classes=useStyles()
    return(
        <Box className={classes.main}>
            <Grid container justifyContent={"center"}  alignItems={"center"}>
                <Grid item>
                    <Typography variant={"h5"}>
                        URL Shortner
                    </Typography>
                </Grid>


            </Grid>

        </Box>
    )
}
export default LandingPage