import React from "react"
import {Box,Grid,Typography,makeStyles} from "@material-ui/core";
import Error from "./3793096.jpg"
const useStyles=makeStyles((theme)=>({
    main:{

        // padding:20

    },
    container:{
        justifyContent:"center",
        alignItems:"center"
// maxWidth:400
    },
    image: {
        display:'block',
        margin:'auto',maxWidth:500,
        [theme.breakpoints.down("xs")]:{
            maxWidth: 368
        },
        [theme.breakpoints.up("md")]:{
            maxWidth: 550
}
    }
    ,
    contentDiv:{
        maxWidth:600
    },

    title:{
        fontSize:"2rem",
        [theme.breakpoints.down("xs")]:{
            fontSize: "1.5rem"
        },
        [theme.breakpoints.up("md")]:{
            fontSize: "1.5rem"
        }

    }
}))
function ErrorPage(){
    const classes=useStyles()
    return(
        <Box component={"main"} className={classes.main}>
            <Grid container className={classes.container}   wrap={"wrap"}>
<Grid item  xs={12} md={12} lg={6}>
    <img src={Error} className={classes.image} />

</Grid>
                <Grid item  xs={12} md={12} lg={6} style={{justifyContent:"center"}}>
                    <Typography component={"div"} variant={"body2"} align={"center"}  className={classes.title}>
                        OOPS! Sorry,&nbsp;The page you are looking for doesn't exists
                    </Typography>
                </Grid>


            </Grid>

        </Box>
    )
}
export  default ErrorPage