import React from "react"
import {Box,Grid,Typography,makeStyles} from "@material-ui/core";
import Error from "./3828550.jpg"
const useStyles=makeStyles((theme)=>({
    main:{

        // padding:20

    },
    container:{
        justifyContent:"center",
        alignItems:"center"
// maxWidth:400
    },
    image: {
        display:'block',
        margin:'auto',maxWidth:650,
        [theme.breakpoints.down("xs")]:{
            maxWidth: 368
        },
        [theme.breakpoints.up("md")]:{
            maxWidth: 600
        }
    }
    ,
    contentDiv:{
        maxWidth:600
    },

    title:{
        fontSize:"2rem",
        [theme.breakpoints.down("xs")]:{
            fontSize: "1.5rem"
        },
        [theme.breakpoints.up("md")]:{
            fontSize: "1.5rem"
        }

    }
}))
function ServerError(){
    const classes=useStyles()
    return(
        <Box component={"main"} className={classes.main}>
            <Grid container className={classes.container} justifyContent={"center"} alignItems={"center"} wrap={"wrap"}>
                <Grid item style={{objectFit:"contain"}}>
                    <img src={Error} className={classes.image} />
                </Grid>


            </Grid>

        </Box>
    )
}
export  default ServerError