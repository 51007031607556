
import LandingPage from "./Landing Page/landingPage";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {callLinkSuccess,clearCode} from "./store/reducer";
import {axiosinstance} from "./store/manager";
import {LinearProgress} from "@material-ui/core"
import {BrowserRouter, Redirect, Route, Switch, useHistory, useLocation, useParams} from "react-router-dom";




import ErrorPage from "./Page/ErrorPage";

import "./index.css"
import ExpiryPage from "./Page/ExpirePage";
import ServerError from "./Page/ServerError";



function App() {
    const dispatch=useDispatch()
    const history=useHistory()
    // console.log(history)
    const location=useLocation()
    const[status,setStatus]=useState('')
    const params =  useParams();
    const allQueryParams = Object.fromEntries(new URLSearchParams(location.search).entries())|| {};

    const {link,status_code,loader}=useSelector(state=>state.linkReducer)

    const pathname=window.location.pathname

    // console.log("Pathname",pathname)
// const status_code=404
function handlePath (code) {
    if(pathname.startsWith("/")){
        if(code.startsWith("d.")){
            axiosinstance.defaults.baseURL=process.env.REACT_APP_DEVELOPMENT_API_DOMAIN
            dispatch(callLinkSuccess({code}))

        }
        else if(code.startsWith("s.")){

            axiosinstance.defaults.baseURL=process.env.REACT_APP_SANDBOX_API_DOMAIN
            // console.log("Sandbox",process.env.REACT_APP_SANDBOX_API_DOMAIN)
            dispatch(callLinkSuccess({code}))
        }
        else if(code.startsWith("p.")){
            axiosinstance.defaults.baseURL=process.env.REACT_APP_PRODUCTION_API_DOMAIN
            dispatch(callLinkSuccess({code}))
        }
        else{
            // console.log("else")
            if(pathname=="/" || pathname=="/expiry"||pathname=="/error" ||pathname=="internal-server-error"){

            }
            else{
                // setStatus(404)
                history.push("/error")
            }
        }
    }
}

    useEffect(()=>{
        if(Object.keys(allQueryParams)?.length){
            const code = Object.values(allQueryParams)[0];
            handlePath(code);
        }else{
            if(pathname.startsWith("/")){
                let code=pathname.slice(1)
                handlePath(code);

            }
        }
        // console.log("render")

        return ()=>{
            dispatch(clearCode())
            // history.push("/")
        }



    },[])

    useEffect(()=>{
        if(status_code==200){
            window.location.href= link
        }
        else if(status_code==404){
            // console.log("status_code",status_code)
            history.push("/error")
        }
        else if(status_code==422){
            // console.log("status_code",status_code)
            history.push("/expiry")
        }
        else if(status_code==500){
            // console.log("status_code",status_code)
            history.push("/internal-server-error")
        }

        setStatus(status_code)
        // console.log(status_code)


    },[status_code])

    // const LinearProgressBar = ()=>(
    //     <>
    //         <div className={classNames(this.props.classes.rootLinear)}>
    //             <LinearProgress/>
    //         </div>
    //     </>
    // )



        return (


                <div className="App">
                    {loader && <LinearProgress/>}

                    <Switch>

                        {status_code==404 &&
                            <Redirect from ={"/"} to={"/error"} exact />}
                        {status==404 &&
                        <Redirect from ={"/"} to={"/error"} exact />}
                        {status_code==422 && <Redirect from={"/"} to={"/expiry"} exact/>}
                        {status_code==500 && <Redirect from={"/"} to={"/internal-server-error"} exact/>}
                            <Route component={LandingPage} exact path={"/"}/>
                                <Route component={ExpiryPage} exact path={"/expiry"}/>
<Route component={ServerError} path={"/internal-server-error"} exact/>
                            <Route component={ErrorPage} exact path={"/error"}/>
                    </Switch>


                </div>





        )



}

export default App;
